import React from "react";

import Layout from "../components/Layout";
import Header from "../components/Header-Other-Pages";
// import Scroll from '../components/Scroll';
import Footer from "../components/Footer";
// import Social from '../components/Social';

import demo2 from "../assets/images/ipad-screen.png";
import astore from "../assets/images/app-store-badge.svg";

const TermsPage = () => (
  <Layout>
    <Header />

    <section className="features" id="features">
      <div className="container">
        <div className="section-heading text-center">
          <h2>TERMS</h2>
          <p className="text-muted">
            An agreements between a service provider and a person who wants to
            use that service
          </p>
          <hr />
        </div>
        <div className="row">
          <div className="col-lg-7 my-auto">
            <div className="container-fluid">
              <div className="row">
                <div className="feature-item">
                  <i className="icon-screen-smartphone text-primary"></i>
                  <h3>Terms of Use</h3>

                  <p className="text-muted">
                    This site and the mobile applications and services available
                    in connection with this site and mobile application (the
                    “App”) are made available to you by Etched Prints, LLC
                    ("Look At Me", “We”) subject to these Terms of Service,
                    including those set forth in the Privacy Policy (the
                    “Terms”). By accessing, using or downloading any materials
                    from the App, you agree to follow and be bound by the Terms,
                    which may be updated by us from time to time without notice
                    to you. You can review the most current version of the Terms
                    at any time here. We and third party providers may make
                    improvements and/or changes in the products, services,
                    mobile applications, features, programs, and prices
                    described in this App at any time without notice. Certain
                    provisions of the Terms may be supplemented or superseded by
                    expressly designated legal notices or terms located on
                    particular pages at this App. The App is not intended for
                    and is not designed to attract children under 13 years of
                    age. If you do not agree with the Terms, please do not use
                    this App. BY CONTINUING TO USE THE APP, YOU ARE INDICATING
                    YOUR AGREEMENT TO THE TERMS AND ALL REVISIONS THEREOF.
                  </p>

                  <p className="text-muted">
                    We reserve the right at any time and from time to time to
                    modify or discontinue, temporarily or permanently, the App
                    or any portion thereof with or without notice. You agree
                    that we shall not be liable to you or to any third party for
                    any modification, suspension or discontinuance of the App or
                    any portion thereof.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-5 my-auto">
            <div className="device-container">
              <div className="device-mockup ipad_air_2 landscape silver">
                <div className="device">
                  <div className="screen">
                    <img src={demo2} className="img-fluid" alt="" />
                  </div>
                  <div className="button"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <h3>Subscription payments, renewals, and cancellation</h3>
          <p className="text-muted">
            If you choose to buy an Look At Me - Daycare; subscription, payment
            will be charged to your Apple ID account at the confirmation of
            purchase. The subscription automatically renews unless it is
            canceled at least 24 hours before the end of the current period.
            Your account will be charged for renewal within 24 hours prior to
            the end of the current period. You can manage and cancel your
            subscriptions by going to your account settings on the App Store
            after purchase. All purchases are final. Look At Me - Daycare,
            cannot issue refunds for purchases charged to your Apple ID.
          </p>
        </div>

        <div className="row">
          <h3>User conduct</h3>
          <p className="text-muted">
            You are solely responsible for maintaining the confidentiality of
            the password associated with your Apple ID and / or account and for
            restricting access to your password and to your computer while
            logged into the App. You accept responsibility for all activities
            that occur under your account or from your computer. We endeavor to
            use reasonable security measures to protect against unauthorized
            access to your account. We cannot, however, guarantee absolute
            security of your account, your Content or the personal information
            you provide, and we cannot promise that our security measures will
            prevent third-party “hackers” from illegally accessing the App or
            its contents. You accept all risks of unauthorized access to the
            User Data and any other information you provide to us.
          </p>
          <p className="text-muted">
            You understand that all information, data, text, software, sound,
            photographs, graphics, video, messages, tags, or other materials
            (“Content”), whether publicly posted or privately transmitted,
            available in connection with the App are the sole responsibility of
            the person from whom such Content originated. We do not control or
            monitor the Content made available in the App by others and do not
            guarantee the accuracy, integrity, or quality of such Content. You
            understand that by using the App, you may be exposed to Content that
            is offensive, indecent, or objectionable. Under no circumstances
            will we be liable in any way for any Content, including, but not
            limited to, any errors or omissions in any Content, or any loss or
            damage of any kind incurred as a result of the use of any Content
            posted, emailed, transmitted or otherwise made available on the App.
          </p>
          <p className="text-muted">
            You agree to not use the App to: (a) upload, post, email, transmit
            or otherwise make available any Content that is unlawful, harmful,
            threatening, abusive, harassing, tortious, defamatory, vulgar,
            obscene, libelous, invasive of another’s privacy, hateful, or
            racially, ethnically or otherwise objectionable; (b) harm minors in
            any way; (c) impersonate any person or entity, including, but not
            limited to, an Look At Me person, or falsely state or otherwise
            misrepresent your affiliation with a person or entity; (d) upload,
            post, email, transmit or otherwise make available any Content that
            you do not have a right to make available under any law or under
            contractual or fiduciary relationships; (e) upload, post, email,
            transmit or otherwise make available any Content that infringes any
            patent, trademark, trade secret, copyright or other proprietary
            rights of any party; (f) upload, post, email, transmit or otherwise
            make available any unsolicited or unauthorized advertising,
            promotional materials, “junk mail,” “spam,” “chain letters,”
            “pyramid schemes,” or any other form of solicitation; (g) upload,
            post, email, transmit or otherwise make available any material that
            contains software viruses or any other computer code, files or
            programs designed to interrupt, destroy or limit the functionality
            of any computer software or hardware or telecommunications
            equipment; (h) interfere with or disrupt the App or servers or
            networks connected to the App, or disobey any requirements,
            procedures, policies or regulations of networks connected to the
            App; and/or (i) intentionally or unintentionally violate any
            applicable local, state, national or international law. You
            specifically agree not to access (or attempt to access) the App or
            the Content through any automated means (including the use of any
            script, web crawler, robot, spider, or scraper), and that you will
            not forge or manipulate identifiers in order to disguise the origin
            of any access (or attempted access) to the App.
          </p>
          <p className="text-muted">
            You acknowledge, consent and agree that we may access, preserve and
            disclose your User Data, Payment Method information, and other
            Content if required to do so by law or in a good faith belief that
            such access preservation or disclosure is reasonably necessary to:
            (a) comply with legal process; (b) enforce the Terms; (c) respond to
            claims that any Content violates the rights of third parties; (d)
            respond to your requests for customer service; or (e) protect the
            rights, property or personal safety of us, its users and the public.
            Subject to the foregoing, we will use reasonable efforts to maintain
            the confidentiality of your User Data and Payment Method
            information.
          </p>
          <p className="text-muted">
            The App is for your personal work needs. You may not modify, copy,
            distribute, transmit, display, perform, reproduce, publish, license,
            create derivative works from, transfer or sell for any commercial
            purposes any portion of the App, use of the App or access to the
            App.
          </p>
        </div>

        <div className="row">
          <h3>Electronic Communications</h3>
          <p className="text-muted">
            The App may provide you with the ability to send emails or other
            communications to third party service providers, advertisers, other
            users, and/or us. You agree to use communication methods available
            on the App only to send communications and materials related to the
            subject matter for which we provided the communication method, and
            you further agree that all such communications by you shall be
            deemed your Content and shall be subject to and governed by the
            Terms. By using any of the communications methods available on the
            App, you acknowledge and agree that (a) all communications methods
            constitute public, and not private, means of communication between
            you and the other party or parties, (b) communications sent to or
            received from third party service providers, advertisers or other
            third parties are not be endorsed, sponsored or approved by us
            (unless expressly stated otherwise by Etched Prints, LLC) and (c)
            communications are not pre-reviewed, post-reviewed, screened,
            archived or otherwise monitored by us in any manner, though we
            reserve the right to do so at any time at its sole discretion in
            accordance with the Terms. You agree that all notices, disclosures
            and other communications that we provide to you electronically shall
            satisfy any legal requirement that such communications be in
            writing.
          </p>
        </div>

        <div className="row">
          <h3>Proprietary Rights</h3>
          <p className="text-muted">
            You acknowledge and agree that the App and any necessary software
            used in connection with the App (if any) contain proprietary and
            confidential information that is protected by applicable
            intellectual property and other laws. Except as expressly permitted
            by applicable law or authorized by us or applicable third party
            service providers or advertisers, you agree not to modify, rent,
            lease, loan, sell, distribute or create derivative works based on
            the App, the software or Content available on the App (other than
            Content that you may submit), in whole or in part.
          </p>

          <p className="text-muted">
            We grant you a personal, non-transferable and non-exclusive right
            and license to access and use the App; provided that you do not (and
            do not allow any third party to) copy, modify, create a derivative
            work from, reverse engineer, reverse assemble, or otherwise attempt
            to discover any source code, sell, assign, sublicense, grant a
            security interest in or otherwise transfer any right in the App. You
            agree not to access the App by any means other than through the
            interface that is provided by us for use in accessing the App.
          </p>

          <p className="text-muted">
            We reserve all rights not expressly granted hereunder.
          </p>
        </div>

        <div className="row">
          <h3>Disclaimer of Warranties and Liability</h3>
          <p className="text-muted">
            THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES, AND CONTENT AVAILABLE
            IN THE APP ARE PROVIDED TO YOU “AS IS” AND WITHOUT WARRANTY. ETCHED
            PRINTS, LLC AND ITS SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES,
            AGENTS, PARTNERS, AND LICENSORS HEREBY DISCLAIM ALL WARRANTIES WITH
            REGARD TO SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND
            CONTENT, INCLUDING, WITHOUT LIMITATION, ALL IMPLIED WARRANTIES AND
            CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
            TITLE AND NONINFRINGEMENT. ETCHED PRINTS, LLC AND ITS SUBSIDIARIES,
            AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS, AND LICENSORS
            MAKE NO WARRANTY THAT (a) THE SERVICE WILL MEET YOUR REQUIREMENTS;
            (b) THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR
            ERROR-FREE; (c) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE
            SERVICE WILL BE ACCURATE OR RELIABLE; (d) THE QUALITY OF ANY
            PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR
            OBTAINED BY YOU THROUGH THE SERVICE WILL MEET YOUR EXPECTATIONS; AND
            (e) ANY ERRORS IN THE APP WILL BE CORRECTED.
          </p>

          <p className="text-muted">
            YOU EXPRESSLY AGREE TO RELEASE ETCHED PRINTS, LLC, ITS SUBSIDIARIES,
            AFFILIATES, OFFICERS, AGENTS, REPRESENTATIVES, EMPLOYEES, PARTNERS
            AND LICENSORS (THE “RELEASED PARTIES”) FROM ANY AND ALL LIABILITY
            CONNECTED WITH YOUR ACTIVITIES, AND PROMISE NOT TO SUE THE RELEASED
            PARTIES FOR ANY CLAIMS, ACTIONS, INJURIES, DAMAGES, OR LOSSES
            ASSOCIATED WITH YOUR ACTIVITIES. YOU ALSO AGREE THAT IN NO EVENT
            SHALL THE RELEASED PARTIES BE LIABLE TO YOU OR ANY THIRD PARTY FOR
            ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL OR CONSEQUENTIAL
            DAMAGES ARISING OUT OF OR IN ANY WAY CONNECTED WITH (a) YOUR USE OR
            MISUSE OF THE APP, (b) YOUR DEALINGS WITH THIRD PARTY SERVICE
            PROVIDERS OR ADVERTISERS AVAILABLE THROUGH THE APP, (c) ANY DELAY OR
            INABILITY TO USE THE APP EXPERIENCED BY YOU, (d) ANY INFORMATION,
            SOFTWARE, PRODUCTS, SERVICES, OR CONTENT OBTAINED THROUGH THE APP,
            WHETHER BASED ON CONTRACT, TORT, STRICT LIABILITY, OR OTHERWISE,
            EVEN IF ETCHED PRINTS, LLC HAS BEEN ADVISED OF THE POSSIBILITY OF
            DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE
            EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL
            DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
          </p>
        </div>

        <div className="row">
          <h3>Indemnity</h3>
          <p className="text-muted">
            You agree to indemnify and hold us and our subsidiaries, affiliates,
            officers, agents, representatives, employees, partners and licensors
            harmless from any claim or demand, including reasonable attorneys’
            fees, made by any third party due to or arising out of Content you
            submit, post, transmit or otherwise seek to make available through
            the App, your use of the App, your connection to the App, your
            violation of the Terms, or your violation of any rights of another
            person or entity.
          </p>
        </div>

        <div className="row">
          <h3>Termination</h3>
          <p className="text-muted">
            You agree that we may, under certain circumstances and without prior
            notice, immediately terminate your account and/or access to the App.
            Cause for such termination shall include, but not be limited to, (a)
            breaches or violations of the Terms or other incorporated agreements
            or guidelines, (b) requests by law enforcement or other government
            agencies, (c) a request by you (self-initiated account deletions),
            (d) discontinuance or material modification to the App (or any
            portion thereof), (e) unexpected technical or security issues or
            problems, (f) extended periods of inactivity, and/or (g) nonpayment
            of any fees owed by you in connection with the App. Termination of
            your account may include (x) removal of access to all offerings
            within the App, (y) deletion of your information, files and Content
            associated with or inside your account, and (z) barring of further
            use of the App. Further, you agree that all terminations for cause
            shall be made in our sole discretion and that we shall not be liable
            to you or any third party for any termination of your account or
            access to the App.
          </p>
        </div>

        <div className="row">
          <h3>Applicable laws</h3>
          <p className="text-muted">
            This App is controlled by Etched Prints, LLC from its offices within
            the United States of America. We make no representation that the
            Content in the App or the App are appropriate or available for use
            in other locations, and access to them from territories where their
            content or use is illegal is prohibited. Those who choose to access
            this App from locations outside of the United States do so on their
            own initiative and are responsible for compliance with applicable
            local laws. You may not use or export the Content in violation of
            U.S. export lays and regulations.
          </p>
        </div>

        <div className="row">
          <h3>General</h3>
          <p className="text-muted">
            You agree that no joint venture, partnership, employment, or agency
            relationship exists between you and Etched Prints, LLC as a result
            of the Terms or your use of the App. The Terms constitute the entire
            agreement between you and Etched Prints, LLC with respect to your
            use of the App. The failure of us to exercise or enforce any right
            or provision of the Terms shall not constitute a waiver of such
            right or provision. If any provision of the Terms is found by a
            court of competent jurisdiction to be invalid, the parties
            nevertheless agree that the court should endeavor to give effect to
            the parties’ intentions as reflected in the provision, and the other
            provisions of the Terms remain in full force and effect. You may not
            assign, delegate or otherwise transfer your account or your
            obligations under these Terms without the prior written consent of
            us. We have the right, in its sole discretion, to transfer or assign
            all or any part of its rights under these Terms and will have the
            right to delegate or use third party contractors to fulfill its
            duties and obligations under these Terms and in connection with the
            App. Our notice to you via email, regular mail, or notices or links
            on the App shall constitute acceptable notice to you under the
            Terms. A printed version of the Terms and of any notice given in
            electronic form shall be admissible in judicial or administrative
            proceedings based upon or relating to the Terms to the same extent
            and subject to the same conditions as other business documents and
            records originally generated and maintained in printed form. Section
            titles and headings in the Terms are for convenience only and have
            no legal or contractual effect. Any rights not expressly granted
            herein are reserved.
          </p>
        </div>
      </div>
    </section>

    <section className="download bg-primary text-center" id="download">
      <div className="container">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <h2 className="section-heading">No More Paper!</h2>

            <p>
              Available on Apple&trade; iOS devices.
              <br></br>
              Download now to get started!
            </p>
            <div className="badges">
              <a className="badge-link" href="https://apps.apple.com/us/app/look-at-me-daycare/id1254621223">
                <img src={astore} alt="Apple Store" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Footer />
  </Layout>
);

export default TermsPage;
